import { Component, OnInit } from '@angular/core';
import { PageMixin } from 'src/app/services/mixins/page.mixin';
import { PageService } from 'src/app/services/page.service';
import { Title, Meta } from '@angular/platform-browser';
import { InfoService } from 'src/app/services/info.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { LangService } from 'src/app/services/lang.service';
import { TranslateService } from '@ngx-translate/core';

import { Observable } from 'rxjs';

import { ClientService } from '../../services/client.service';
import { BaseComponent } from '../base.component';

@Component({
    selector: 'app-static',
    templateUrl: './static.component.html',
})
export class StaticComponent extends PageMixin(BaseComponent) implements OnInit {
    public translate$: Observable<string>;

    private type: string;

    public constructor(
        public page: PageService,
        protected translate: TranslateService,
        protected client: ClientService,
        protected router: ActivatedRoute,
        protected title?: Title,
        protected meta?: Meta,
        protected route?: Router,
        protected info?: InfoService,
        public lang?: LangService,
    ) {
        super(page, title, meta, route, info);
    }

    public ngOnInit(): void {
        super.ngOnInit();


        this.router.paramMap.subscribe((params: ParamMap) => {
            if (params.has('type')) {
                this.type = params.get('type') ?? 'not_found';
                this.translate$ = this.page.getStaticPageTranslate(this.type);
                this.page.setPageTitle(`web.page_static_${this.type}`);
                this.lang!.getLang().then((): Promise<any> => this.createTitle());
            }
        });
    }

    /**
     * Create page title and meta
     * @return Promise<any>
     */
    private createTitle(): any {
        this.translate.get(`web.page_static_${this.type}`).subscribe((): void => {
            const trans = this.translate.instant(`web.page_static_${this.type}`);
            const client = this.client.getVName();
            this.setTitle(
                {
                    title: `${trans} | ${client}`,
                    description: this.translate.instant(`web.page_static_description_${this.type}`),
                },
                true,
            );
        });
    }
}
