import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';


import { ComponentModule } from '@components/component.module';


import { StaticRoutingModule } from '@pages/static/static-routing.module';

import { StaticComponent } from '@pages/static/static.component';


import { SharedModule } from '@/shared/shared.module';

@NgModule({
    declarations: [
        StaticComponent,

    ],
    imports: [CommonModule, SharedModule, StaticRoutingModule, ComponentModule],
})
export class StaticModule {}
