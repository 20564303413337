<app-header [fullMenu]="false" [sportMenu]="true"></app-header>

<main role="main">
    <div class="container p-2 pt-md-6 p-md-4 bg-light position-relative app-match-detail-page">
        <ng-container *ngIf="translate$ | async as trans">
            <div [innerHTML]="trans | markdown" class="text-justify"></div>
        </ng-container>
    </div>
</main>
<app-footer></app-footer>
